import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import Header from "../header";
import { useChat } from "ai/react";
import { ScrollArea } from "../ui/scroll-area";
import { Separator } from "../ui/separator";
import { Textarea } from "../ui/textarea";
import { Check, Clipboard, Loader2, Send, StopCircle } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui/hovercard";
import { Loading } from "../ui/loader";
import { useCopyToClipboard } from "../ui/use-copy-to-clipboard";
import { ChatAction } from "../ui/chataction";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MdContentCopy, MdReplay } from "react-icons/md";
import Markdown from "../ui/markdown";
import { nanoid } from 'nanoid';
import botstream from '../../assets/botstreams.png'

const Chat = ({ 
  bot,
  user,
  conversationId,
  messages,
  setMessages,
  isError,
  errorMessage,
  setIsError,
  setErrorMessage,
  input,
  setInput,
  handleSubmit,
  handleInputChange,
  isLoading,
  reload,
  stop,
  isManual,
  manualChatSave,
  textareaRef,
  support,
 }) => {
  const scrollRef = useRef(null);
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 1000 });

  const updateMessages = () => {
    const newMessages = [...messages, ...(bot?.session?.messages ?? [])];
    setMessages(newMessages);
  };

  useEffect(() => {
    updateMessages();
  }, [bot?.session?.messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onSubmit = (e) => {
    if(isManual){
      manualChatSave(e);
    }
    else handleSubmit(e);
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  return (
    <div className={`flex flex-col relative w-full ${support && 'h-[90%]'}`}>
      <Header bot={bot} />
      <ScrollArea
        ref={scrollRef}
        className="flex-1 overflow-auto overflow-x-hidden relative overscroll-none pb-5 p-5"
      >
        <div className="space-y-5">
          {messages.map((message, i) => {
            const isUser = message.role === "user";
            // const isContext = i < context.length;
            const showActions = i > 0 && !(message.content.length === 0);
            const showThinking = message.streaming;

            return (
              <div className="space-y-5" key={i}>
                <div
                  className={
                    isUser
                      ? "flex flex-row-reverse"
                      : "flex flex-row last:animate-[slide-in_ease_0.3s]"
                  }
                >
                  <HoverCard openDelay={200}>
                    <HoverCardTrigger asChild>
                      <div
                        className={`max-w-[80%] flex flex-col items-start ${
                          isUser ? "items-end" : ""
                        }`}
                      >
                        {showThinking && (
                          <div
                            className={
                              "text-xs text-[#aaa] leading-normal my-1"
                            }
                          >
                            Thinking...
                          </div>
                        )}
                        <div
                          className={`box-border max-w-full text-sm select-text relative break-words rounded-lg px-3 py-2 ${
                            isUser ? "bg-[#16A34A] text-white" : "bg-gray-100"
                          }`}
                        >
                          <Markdown content={message.content} />
                        </div>
                        <div className="text-xs text-muted-foreground opacity-80 whitespace-nowrap text-right w-full box-border pointer-events-none z-[1] mt-1">
                          {formatDate(message?.createdAt)} {message?.sender ? `- ${message?.sender}` : ""}
                        </div>
                      </div>
                    </HoverCardTrigger>
                    {showActions && (
                      <HoverCardContent
                        side="top"
                        align={isUser ? "end" : "start"}
                        className="py-1 px-0 w-fit"
                      >
                        <div className="flex items-center">
                          {!message.streaming && (
                            <>
                              <ChatAction
                                text={isCopied ? "Copied" : "Copy"}
                                icon={
                                  isCopied ? (
                                    <Check className="w-4 h-4" />
                                  ) : (
                                    <Clipboard className="w-4 h-4" />
                                  )
                                }
                                onClick={() => {
                                  copyToClipboard(message.content);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </HoverCardContent>
                    )}
                  </HoverCard>
                </div>
              </div>
            );
          })}
          {/* This is error part */}
          {isError && (
            <div className="flex items-end gap-3">
              <div className="space-y-1">
                <div className="text-[#16171D] text-sm bg-red-200 border border-red-500 p-2 rounded-md">
                  {errorMessage}
                </div>
                <div className="flex items-center gap-2 mt-4 text-gray/500">
                  <button className="">
                    <MdContentCopy />
                  </button>
                  <button>
                    <MdReplay
                      onClick={() => {
                        reload();
                        setIsError(false);
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* This is thinking part */}
          {isLoading && (
            <div
              className={
                "text-xs text-[#aaa] leading-normal my-1 flex items-center gap-1"
              }
            >
              Thinking <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          )}
        </div>
      </ScrollArea>
      {/* <Separator /> */}
      <form
        onSubmit={onSubmit}
        className="relative w-full box-border flex-col pt-2.5 p-5 space-y-2"
      >
        <div className="flex flex-1 items-end relative">
          <Textarea
            autoFocus
            ref={textareaRef}
            name="message"
            readOnly={isLoading}
            className="ring-inset focus-visible:ring-offset-0 pr-28 md:pr-40 min-h-[56px] focus:ring-2 focus:ring-[#16A34A]"
            placeholder="Enter to send"
            value={input}
            onClick={scrollToBottom}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onSubmit(e);
              }
            }}
          />
          <div className="absolute right-[15px] top-1/2 transform -translate-y-1/2">
            {!isLoading ? <button
              type="submit"
              disabled={isLoading}
              className="border flex items-center gap-2 px-3 py-3 md:py-2 rounded-md bg-[#16A34A] text-white disabled:opacity-50 hover:opacity-90 transition-opacity duration-200"
            >
              <Send className="h-4 w-4" />
              <p className="text-sm font-medium md:block hidden">Send</p>
            </button> :
            <button
              type="button"
              onClick={() => stop()}
              className="border flex items-center gap-2 px-3 py-3 md:py-2 rounded-md bg-[#16A34A] text-white disabled:opacity-50 hover:opacity-90 transition-opacity duration-200"
            >
              <StopCircle className="h-4 w-4" />
              <p className="text-sm font-medium md:block hidden">Stop</p>
            </button>}
          </div>
        </div>
      </form>
      <a href='https://botstreams.ai/' target="_blank" className="text-gray/500 flex items-center justify-center gap-2 text-sm -mt-3 mb-2">
        Powered by <img src={botstream} alt="botstreams" />
      </a>
    </div>
  );
};

export default Chat;
