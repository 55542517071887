import React from "react";
import { useHandleSignInCallback, useLogto } from "@logto/react";
import { useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";

const Callback = ({ setLoading, setToken, setUser }) => {
  const { fetchUserInfo, getAccessToken } = useLogto();
  const navigate = useNavigate();

  const { isLoading } = useHandleSignInCallback(async () => {
    // const accessToken = await getAccessToken(process.env.REACT_APP_ADMIN_API_URL);
    // setToken(accessToken);
    // localStorage.setItem('token', accessToken);

    const user = await fetchUserInfo();
    // setUser(user);
    navigate('/')
    const redirectUri = sessionStorage.getItem('redirectAfterLogin')

    if(redirectUri) {
      setLoading(false);
      sessionStorage.removeItem('redirectAfterLogin');
      navigate(redirectUri)
    }
    // else if(user.organizations.length === 0){
    //   setLoading(false);
    //   navigate('/form')
    // } 
    else {
      setLoading(false);
      navigate('/')
    }
  });

  // if (isLoading) {
  //   setLoading(true);
  // }

  return (
    <div className="h-screen flex items-center justify-center text-center">
      <div className="w-full h-screen max-h-full flex items-center justify-center text-sm text-muted-foreground">
        <Loader2 className="size-8 animate-spin" />
      </div>
    </div>
  )
};

export default Callback;
