import React, { useEffect, useState } from "react";
import axios from "axios";
import { Outlet, useParams, Navigate } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import Bot from "./pages/bot";
import { Loader2 } from "lucide-react";

const BotStatus = ({ user, token, loading, setLoading, setShowAlert, support }) => {
  const { botId } = useParams();  
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/bot/is-public/${botId}`)
      .then((res) => {
        setStatus(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching bot status:', error);
        setStatus(false);
        setLoading(false);
      });
  }, [botId]);

  if (status === null) {
    return (
        <div className="h-screen flex items-center justify-center text-center">
            <div className="w-full h-screen max-h-full flex items-center justify-center text-sm text-muted-foreground">
            <Loader2 className="size-8 animate-spin" />
            </div>
        </div>
    )
  }

  if (status) {
    return <Bot loading={loading} setLoading={setLoading} user={user} token={token} status={status} setShowAlert={setShowAlert} support={support} />
  } else {
    return (
      <RequireAuth>
        <Bot loading={loading} setLoading={setLoading} user={user} token={token} setShowAlert={setShowAlert} support={support} />
      </RequireAuth>
    );
  }
};

export default BotStatus;