import React from 'react';
import { Root as SeparatorPrimitive } from '@radix-ui/react-separator';

const Separator = React.forwardRef((props, ref) => {
  const { className, orientation = 'horizontal', decorative = true, ...otherProps } = props;
  return (
    <SeparatorPrimitive
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={`shrink-0 bg-gray-300 ${orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]'} ${className}`}
      {...otherProps}
    />
  );
});

Separator.displayName = 'Separator';

export { Separator };