import React, { useEffect, useState } from "react";
import { PiChatsBold } from "react-icons/pi";
import BotStatus from "../../BotStatus";
import { RxCross2 } from "react-icons/rx";

const SupportChat = ({ loading, setLoading, user, token, support }) => {
  const [openIframe, setOpenIframe] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if(openIframe){
      setShowAlert(false);
    }
  }, [showAlert])

  return (
    <div className="">
      <div className={`bg-white transition-opacity duration-300 h-[90vh] ${openIframe ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <BotStatus loading={loading} setLoading={setLoading} user={user} token={token} setShowAlert={setShowAlert} support={support} />
      </div>
      <div className="flex justify-end mt-3">
        <button
          className="text-white bg-blue/500 size-10 lg:size-16 rounded-full flex justify-center items-center hover:animate-pulse shrink-0 relative"
          onClick={() => {setOpenIframe(!openIframe); setShowAlert(false)}}
        >
          {!openIframe ? <PiChatsBold className="text-xl lg:text-4xl" /> : <RxCross2 className="text-xl lg:text-4xl" />}
          {!openIframe && showAlert && <div className="size-3 bg-red-500 rounded-full absolute top-0 right-0"></div>}
        </button>
      </div>
    </div>
  );
};

export default SupportChat;