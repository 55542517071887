import React, { useEffect, useState } from "react";
import RequireAuth from "../../RequireAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useLogto } from "@logto/react";
import axios from "axios";
import { Loader2 } from "lucide-react";

const Join = ({ getTokens }) => {
  const { inviteId } = useParams();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const { isAuthenticated, getAccessToken, fetchUserInfo } = useLogto();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) { 
        const user = await fetchUserInfo();
        setUser(user);
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      getInviteStatus();
    }
  }, [user]);

  const getInviteStatus = async () => {
    setLoading(true);
    const accessToken = await getAccessToken(process.env.REACT_APP_ADMIN_API_URL);
    axios
      .get(`${process.env.REACT_APP_ADMIN_API_URL}/organizations/invite/${inviteId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        console.log(res, "THis is status");
        setLoading(false);
        if (res?.data?.data?.status === "Pending") {
          acceptInvite();
        } else if (res?.data?.data?.status === "Accepted") {
          setStatus("You are already accepted");
          navigate("/");
        } else if (res?.data?.data?.status === "Revoked") {
          setStatus("This link as been revoked");
        } else if (res?.data?.data?.status === "Expired") {
          setStatus("This link has expired");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const acceptInvite = async () => {
    setLoading(true);
    const accessToken = await getAccessToken(process.env.REACT_APP_ADMIN_API_URL);
    axios
      .post(`${process.env.REACT_APP_ADMIN_API_URL}/organizations/invite/accept/${inviteId}`, {
        acceptedUserId: user?.sub,
        status: "Accepted",
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        console.log(res, "THis is invite");
        getTokens();
        navigate("/");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if(loading) return (
    <div className="h-screen flex items-center justify-center text-center">
      <div className="w-full h-screen max-h-full flex items-center justify-center text-sm text-muted-foreground">
        <Loader2 className="size-8 animate-spin" />
      </div>
    </div>
  )

  return (
    <div className='text-2xl font-bold flex justify-center items-center h-screen'>{status}</div>
  );
};

export default Join;
