import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useCopyToClipboard } from "../../components/ui/use-copy-to-clipboard";
import { useChat } from "ai/react";
import axios from "axios";
import {
  LucideCircleStop,
  LucideFileDown,
  LucideMic,
  LucideSendHorizonal,
  LucideShare2,
} from "lucide-react";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Loading } from "../../components/ui/loader";
import { SiKlarna } from "react-icons/si";
import { useParams } from "react-router-dom";
import { MdContentCopy, MdReplay } from "react-icons/md";
import Markdown from "../../components/ui/markdown";
import { nanoid } from 'nanoid';
import { io } from "socket.io-client";
import { LuMaximize2 } from "react-icons/lu";
import logo from '../../assets/v2.png'
import botstream from '../../assets/botstreams.png'

const DayOneChat = ({ user, bot, org, setShowAlert, support, signedImg, sendNotification }) => {
  const scrollRef = useRef(null);
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 1000 });
  const { botId } = useParams();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const textareaRef = useRef(null);
  const messagesRef = useRef([]);
  const [conversationId, setConversationId] = useState(null);
  const conversationIdRef = useRef(conversationId);  // Add this ref
  const [socket, setSocket] = useState(null);
  const [isManual, setIsManual] = useState();
  const [conversations, setConversations] = useState([]);
  const [userId] = useState(() => {
    if (user?.sub) {
      return user.sub;
    } else {
      const storedGuestId = localStorage.getItem("guest_id");
      if (storedGuestId) {
        return storedGuestId; 
      } else {
        const newGuestId = `guest-${nanoid(5)}`;
        localStorage.setItem("guest_id", newGuestId);
        return newGuestId;
      }
    }
  });

  const {
    messages,
    setMessages,
    input,
    setInput,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    stop,
  } = useChat({
    api: `${process.env.REACT_APP_CHAT_API}/api/chat`,
    headers: {
      "Content-Type": "application/json",
    },
    body: {
      config: bot?.modelConfig,
      datasource: botId,
    },
    onError: (error) => {
      let errorMessage = "An unknown error occurred.";
    
      if (error.message) {
        if (typeof error.message === "string") {
          try {
            // Check if the error message is valid JSON
            const parsedError = JSON.parse(error.message);
            errorMessage = parsedError.detail || parsedError.error || errorMessage;
          } catch (e) {
            // If JSON parsing fails, treat it as plain text
            errorMessage = error.message;
          }
        } else if (
          typeof error.message === "object" &&
          error.message !== null
        ) {
          errorMessage = error.message.detail || JSON.stringify(error.message);
        }
      } else if (typeof error === "string") {
        try {
          // Check if the error is valid JSON
          const parsedError = JSON.parse(error);
          errorMessage = parsedError.detail || parsedError.error || errorMessage;
        } catch (e) {
          // If JSON parsing fails, treat it as plain text
          errorMessage = error;
        }
      } else if (typeof error === "object" && error !== null) {
        errorMessage = error.detail || JSON.stringify(error);
      }
    
      console.log(error, "This is error");
      setErrorMessage(errorMessage);
      setIsError(true);
    },
    onFinish: () => {
      if(userId) {
        handleSendMessage(messagesRef.current);
        sendMessage(conversationId);
      }
      textareaRef.current.focus();
    },
  });

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_ADMIN_API_URL);
    setSocket(newSocket);

    // Join the room when the socket connects
    newSocket.on('connect', () => {
      newSocket.emit('joinRoom', org);
    });

    // Listen for messages from the server
    newSocket.on('message', (data) => {
      // alert(`Message received: ${data}`); // Access the content of the message
      // console.log(conversationIdRef.current, "This is the conversation id in the socket");
      getBotChats();
      if(data === conversationIdRef.current) getConversation(conversationIdRef.current);
      if(support) setShowAlert(true);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [org]);

  useEffect(() => {
    conversationIdRef.current = conversationId;  // Sync the ref with the state
  }, [conversationId]);

  const sendMessage = (message) => {
    if (socket) {
      console.log(`Sending message: ${message} to room: ${org}`);
      socket.emit('message', { room: org, message }); // Include room and message content
    }
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (userId) {
      getBotChats();
    }
  }, [userId]);

  const getBotChats = async () => {
    await axios
      .get(`${process.env.REACT_APP_CHAT_API}/api/chat/get_chat_history/${userId}/${botId}`)
      .then((res) => {
        console.log(res, "This is all chat");
        setConversations(res.data.chats);
        if(res.data.chats.today.length > 0) {
          setConversationId(res.data.chats.today[0]._id);
          setMessages(res.data.chats.today[0].session);
        }
        else if(res.data.chats.yesterday.length > 0) {
          setConversationId(res.data.chats.yesterday[0]._id);
          setMessages(res.data.chats.yesterday[0].session);
        }
        else if(res.data.chats.last_week.length > 0) {
          setConversationId(res.data.chats.last_week[0]._id);
          setMessages(res.data.chats.last_week[0].session);
        }
        else if(res.data.chats.last_month.length > 0) {
          setConversationId(res.data.chats.last_month[0]._id);
          setMessages(res.data.chats.last_month[0].session);
        }
        else if(res.data.chats.older.length > 0) {
          setConversationId(res.data.chats.older[0]._id);
          setMessages(res.data.chats.older[0].session);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getConversation = async (currentConversationId) => {
    await axios
      .get(`${process.env.REACT_APP_CHAT_API}/api/chat/get_conversation/${currentConversationId}`)
      .then((res) => {
        // console.log(res, "This is conversation");
        setMessages(res.data.conversation.session);
        setIsManual(res?.data?.conversation?.manual);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleSendMessage = async (messages) => {
    const sessionData = {
        session: { messages },
    };
    const response = await axios.post(`${process.env.REACT_APP_CHAT_API}/api/chat/save_chat/${userId}/${botId}`, sessionData, {
        params: { conversation_id: conversationId }
    });
    console.log(response, "This is save response")
    if (!conversationId) {
      setConversationId(response.data.conversation_id);
    }
    if(conversations.today.length === 0 && conversations.yesterday.length === 0 && conversations.last_week.length === 0 && conversations.last_month.length === 0 && conversations.older.length === 0){
      sendNotification();
    }
    getBotChats();
  };

  const manualChatSave = async (e) => {
    e.preventDefault();
    if (input.length > 0) {
      const customObj = {
        id: nanoid(7),
        content: input,
        role: "user",
        createdAt: new Date(),
      };

      const customMessages = [
        ...messages,
        customObj
      ]

      if(userId) {
        await handleSendMessage(customMessages);
        sendMessage(conversationId);
      }

      setInput('');
    }
  };

  const updateMessages = () => {
    const newMessages = [...messages, ...(bot?.session?.messages ?? [])];
    setMessages(newMessages);
  };

  useEffect(() => {
    updateMessages();
  }, [bot?.session?.messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onSubmit = (e) => {
    if(isManual){
      manualChatSave(e);
    }
    else handleSubmit(e);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const textareas = document.querySelectorAll("textarea");
    textareas.forEach((element) => {
      element.style.height = `${element.scrollHeight}px`;
      element.addEventListener("input", (event) => {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
      });
    });

    // Cleanup event listeners on component unmount
    return () => {
      textareas.forEach((element) => {
        element.removeEventListener("input", (event) => {
          event.target.style.height = "auto";
          event.target.style.height = `${event.target.scrollHeight}px`;
        });
      });
    };
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  return (
    <main className={`bg-gray/50 flex flex-col w-screen box-border ${support ? "h-[100%]" : "h-screen"}`}>
      <header className="bg-white border-b p-3 px-6 flex justify-between items-center shrink-0">
        <h2 className="font-semibold text-gray-950">{bot?.name}</h2>
        <div className="text-gray-950 flex items-center gap-3 sm:gap-8">
          <a className="text-sm font-medium flex items-center gap-2 sm:hidden" href={`${process.env.REACT_APP_CALLBACK_URL}/${botId}`} target="_blank">
            <LuMaximize2 className="w-[20px] text-gray/500" />
          </a>
          {/* <button className="text-sm font-medium flex items-center gap-2">
            <LucideFileDown className="w-[20px] text-gray/500" />
            <span className="hidden sm:block">Export</span>
          </button>
          <button className="text-sm font-medium flex items-center gap-2 ">
            <LucideShare2 className="w-[20px] text-gray/500" />
            <span className="hidden sm:block">Share</span>
          </button> */}
        </div>
      </header>

      <div className="text-sm text-gray/950 font-medium text-center m-5 shrink-0">
        <span className="bg-gray/100 p-2 px-3 rounded-md">Today</span>
      </div>

      <section className="w-5/6 sm:w-2/3 2xl:w-3/4 mx-auto grow flex flex-col overflow-hidden pb-5">
        <ScrollArea
            ref={scrollRef}
            className="grow overflow-auto overflow-x-hidden relative overscroll-none pb-5"
          >
            <div className="space-y-5">
              {messages.map((message, i) => {
                const isUser = message.role === "user";
                // const isContext = i < context.length;
                const showActions = i > 0 && !(message.content.length === 0);
                const showThinking = message.streaming;

                return (
                  <div className="space-y-5" key={i}>
                    {isUser ? (
                      <div className="flex justify-end items-end gap-3">
                        <div className="space-y-1">
                          <h2 className="text-gray/500 text-xs text-right">
                            You
                          </h2>
                          <div className="text-white font-medium bg-gray/950 p-3 px-5 rounded-xl">
                            <Markdown content={message.content} />
                          </div>
                          <h2 className="text-gray/500 text-xs text-right">
                            {formatDate(message?.createdAt)}
                          </h2>
                        </div>
                        <img
                          className="size-10 object-cover rounded-full mb-7"
                          src={
                            user?.picture
                              ? user?.picture
                              : `https://ui-avatars.com/api/?name=${user?.email ? user?.email : 'G'}&bold=true&color=fff&background=${user ? 'cdbbfa' : 'e01e1e'}`
                            }
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="flex items-end gap-3">
                        <img className="size-10 mb-7 rounded-full" src={signedImg ? signedImg : 'https://storage.googleapis.com/botstreams-bucket/public/avatar.jpg'} alt="" />
                        {/* <div className="size-10 rounded-full bg-[#D51D50] flex items-center justify-center text-[#17120F] shrink-0 font-medium mb-7">
                          D
                        </div> */}
                        <div className="space-y-1">
                          <h2 className="text-gray/500 text-xs">{message?.sender ? message?.sender : bot?.name}</h2>
                          <div className="text-[#16171D] font-medium bg-gray/100 p-3 px-5 rounded-xl">
                            <Markdown content={message.content} />
                          </div>
                          <h2 className="text-gray/500 text-xs">{formatDate(message?.createdAt)}</h2>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* This is error part */}
            {isError && <div className="flex items-end gap-3">
              <div className="size-10 rounded-full bg-[#D51D50] flex items-center justify-center text-[#17120F] shrink-0 font-medium mb-7">
                D
              </div>
              <div className="space-y-1">
                <h2 className="text-gray/500 text-xs">{bot.name}</h2>
                <div className="text-[#16171D] font-medium bg-red-200 border border-red-500 p-3 px-5 rounded-xl">
                  {errorMessage}
                </div>
                <div className="flex items-center gap-2 mt-4 text-gray/500">
                  <h2 className="text-gray/500 text-xs">12:15pm</h2>
                  <button className="">
                    <MdContentCopy />
                  </button>
                  <button>
                    <MdReplay onClick={() => {reload(); setIsError(false)}} />
                  </button>
                </div>
              </div>
            </div>}
            {/* This is thinking part */}
            {isLoading && (
              <h1 className="flex items-center gap-1 text-sm text-gray/500 mt-2 ml-14">
                {bot.name} is thinking <div className="loader"></div>
              </h1>
            )}
        </ScrollArea>

        <form
          onSubmit={onSubmit}
          className=" bg-white border rounded-md p-2.5 flex items-end gap-4"
        >
          <textarea
            autoFocus
            ref={textareaRef}
            placeholder="Write a message"
            rows={1}
            className="resize-none w-full bg-transparent outline-none mb-2.5 ml-4 max-h-60"
            value={input}
            readOnly={isLoading}
            onClick={scrollToBottom}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onSubmit(e);
              }
            }}
          ></textarea>
          {!isLoading ? <button className="bg-[#D51D50] rounded-full p-2">
            <LucideSendHorizonal className="text-[#16171D]" />
          </button> :
            <button className="bg-[#D51D50] rounded-full p-2" onClick={() => stop()}>
              <LucideCircleStop className="text-[#16171D]" />
            </button>}
        </form>
        <a href='https://botstreams.ai/' target="_blank" className="text-gray/500 flex items-center justify-center gap-2 text-sm mt-2">
          Powered by <img src={botstream} alt="botstreams" />
        </a>
      </section>
    </main>
  );
};

export default DayOneChat;
