import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { LogtoProvider, UserScope } from "@logto/react";

const config = {
  endpoint: process.env.REACT_APP_LOGTO_ENDPOINT,
  appId: process.env.REACT_APP_LOGTO_APP_ID,
  resources: [process.env.REACT_APP_ADMIN_API_URL],
  scopes: [UserScope.Organizations, UserScope.OrganizationRoles, UserScope.Profile, UserScope.Email],
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LogtoProvider config={config}>
      <Router>
        <App />
      </Router>
    </LogtoProvider>
  </React.StrictMode>
);
