import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import {
  LucideCircleStop,
  LucideFileDown,
  LucideMic,
  LucidePlay,
  LucideSend,
  LucideSendHorizonal,
  LucideShare2,
} from "lucide-react";
import { SiKlarna } from "react-icons/si";
import { ScrollArea } from "../../components/ui/scroll-area";
import { useCopyToClipboard } from "../../components/ui/use-copy-to-clipboard";
import { useChat } from "ai/react";
import axios from "axios";
import { Loading } from "../../components/ui/loader";
import { BsPauseFill, BsPlayFill, BsStopFill } from "react-icons/bs";
import { MdArrowDropDown, MdContentCopy, MdReplay } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useParams } from "react-router-dom";
import alex from '../../assets/alex.png'
import { FaCode, FaRegStar } from "react-icons/fa";
import { LuMaximize2, LuWallet } from "react-icons/lu";
import { VscBeaker } from "react-icons/vsc";
import Markdown from "../../components/ui/markdown";
import { IconPickerItem } from "react-icons-picker";
import { nanoid } from 'nanoid';
import { io } from "socket.io-client";
import botstream from '../../assets/botstreams.png'

const AudioChat = ({ bot, user, org, setShowAlert, support, signedImg, sendNotification }) => {
  const scrollRef = useRef(null);
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 1000 });
  const [activeVoice, setActiveVoice] = useState("Alex");
  const [showVoices, setShowVoices] = useState({});
  const { botId } = useParams();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [analysisOpen, setAnalysisOpen] = useState(true);
  const textareaRef = useRef(null);
  const messagesRef = useRef([]);
  const [conversationId, setConversationId] = useState(null);
  const conversationIdRef = useRef(conversationId);  // Add this ref
  const [socket, setSocket] = useState(null);
  const [isManual, setIsManual] = useState();
  const [conversations, setConversations] = useState([]);
  const [userId] = useState(() => {
    if (user?.sub) {
      return user.sub;
    } else {
      const storedGuestId = localStorage.getItem("guest_id");
      if (storedGuestId) {
        return storedGuestId; 
      } else {
        const newGuestId = `guest-${nanoid(5)}`;
        localStorage.setItem("guest_id", newGuestId);
        return newGuestId;
      }
    }
  });
  
  const toggleVoiceDropdown = (index) => {
    setShowVoices((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state for the specific dropdown
    }));
  };

  const {
    messages,
    setMessages,
    input,
    setInput,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    stop,
  } = useChat({
    api: `${process.env.REACT_APP_CHAT_API}/api/chat`,
    headers: {
      "Content-Type": "application/json",
    },
    body: {
      config: bot?.modelConfig,
      datasource: botId,
    },
    onError: (error) => {
      let errorMessage = "An unknown error occurred.";
    
      if (error.message) {
        if (typeof error.message === "string") {
          try {
            // Check if the error message is valid JSON
            const parsedError = JSON.parse(error.message);
            errorMessage = parsedError.detail || parsedError.error || errorMessage;
          } catch (e) {
            // If JSON parsing fails, treat it as plain text
            errorMessage = error.message;
          }
        } else if (
          typeof error.message === "object" &&
          error.message !== null
        ) {
          errorMessage = error.message.detail || JSON.stringify(error.message);
        }
      } else if (typeof error === "string") {
        try {
          // Check if the error is valid JSON
          const parsedError = JSON.parse(error);
          errorMessage = parsedError.detail || parsedError.error || errorMessage;
        } catch (e) {
          // If JSON parsing fails, treat it as plain text
          errorMessage = error;
        }
      } else if (typeof error === "object" && error !== null) {
        errorMessage = error.detail || JSON.stringify(error);
      }
    
      console.log(error, "This is error");
      setErrorMessage(errorMessage);
      setIsError(true);
    },
    onFinish: () => {
      if(userId) {
        handleSendMessage(messagesRef.current);
        sendMessage(conversationId);
      }
      textareaRef.current.focus();
    },
  });

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_ADMIN_API_URL);
    setSocket(newSocket);

    // Join the room when the socket connects
    newSocket.on('connect', () => {
      newSocket.emit('joinRoom', org);
    });

    // Listen for messages from the server
    newSocket.on('message', (data) => {
      // alert(`Message received: ${data}`); // Access the content of the message
      // console.log(conversationIdRef.current, "This is the conversation id in the socket");
      getBotChats();
      if(data === conversationIdRef.current) getConversation(conversationIdRef.current);
      if(support) setShowAlert(true);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [org]);

  useEffect(() => {
    conversationIdRef.current = conversationId;  // Sync the ref with the state
  }, [conversationId]);

  const sendMessage = (message) => {
    if (socket) {
      console.log(`Sending message: ${message} to room: ${org}`);
      socket.emit('message', { room: org, message }); // Include room and message content
    }
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (userId) {
      getBotChats();
    }
  }, [userId]);

  const getBotChats = async () => {
    await axios
      .get(`${process.env.REACT_APP_CHAT_API}/api/chat/get_chat_history/${userId}/${botId}`)
      .then((res) => {
        console.log(res, "This is all chat");
        setConversations(res.data.chats);
        if(res.data.chats.today.length > 0) {
          setConversationId(res.data.chats.today[0]._id);
          setMessages(res.data.chats.today[0].session);
        }
        else if(res.data.chats.yesterday.length > 0) {
          setConversationId(res.data.chats.yesterday[0]._id);
          setMessages(res.data.chats.yesterday[0].session);
        }
        else if(res.data.chats.last_week.length > 0) {
          setConversationId(res.data.chats.last_week[0]._id);
          setMessages(res.data.chats.last_week[0].session);
        }
        else if(res.data.chats.last_month.length > 0) {
          setConversationId(res.data.chats.last_month[0]._id);
          setMessages(res.data.chats.last_month[0].session);
        }
        else if(res.data.chats.older.length > 0) {
          setConversationId(res.data.chats.older[0]._id);
          setMessages(res.data.chats.older[0].session);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getConversation = async (currentConversationId) => {
    await axios
      .get(`${process.env.REACT_APP_CHAT_API}/api/chat/get_conversation/${currentConversationId}`)
      .then((res) => {
        // console.log(res, "This is conversation");
        setMessages(res.data.conversation.session);
        setIsManual(res?.data?.conversation?.manual);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleSendMessage = async (messages) => {
    const sessionData = {
        session: { messages },
    };
    const response = await axios.post(`${process.env.REACT_APP_CHAT_API}/api/chat/save_chat/${userId}/${botId}`, sessionData, {
        params: { conversation_id: conversationId }
    });
    console.log(response, "This is save response")
    if (!conversationId) {
      setConversationId(response.data.conversation_id);
    }
    if(conversations.today.length === 0 && conversations.yesterday.length === 0 && conversations.last_week.length === 0 && conversations.last_month.length === 0 && conversations.older.length === 0){
      sendNotification();
    }
    getBotChats();
  };

  const manualChatSave = async (e) => {
    e.preventDefault();
    if (input.length > 0) {
      const customObj = {
        id: nanoid(7),
        content: input,
        role: "user",
        createdAt: new Date(),
      };

      const customMessages = [
        ...messages,
        customObj
      ]

      if(userId) {
        await handleSendMessage(customMessages);
        sendMessage(conversationId);
      }

      setInput('');
    }
  };

  const updateMessages = () => {
    const newMessages = [...messages, ...(bot?.session?.messages ?? [])];
    setMessages(newMessages);
  };

  useEffect(() => {
    updateMessages();
  }, [bot?.session?.messages]);

  useEffect(() => {
    scrollToBottom();
    setIsError(false);
  }, [messages]);

  const onSubmit = (e) => {
    if(isManual){
      manualChatSave(e);
    }
    else handleSubmit(e);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const textareas = document.querySelectorAll("textarea");
    textareas.forEach((element) => {
      element.style.height = `${element.scrollHeight}px`;
      element.addEventListener("input", (event) => {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
      });
    });

    // Cleanup event listeners on component unmount
    return () => {
      textareas.forEach((element) => {
        element.removeEventListener("input", (event) => {
          event.target.style.height = "auto";
          event.target.style.height = `${event.target.scrollHeight}px`;
        });
      });
    };
  }, []);

  const voices = [
    {
      name: "Alex",
      voice: "Female English (UK)",
    },
    {
      name: "James",
      voice: "Male English (UK)",
    },
    {
      name: "Marcus",
      voice: "Male English (US)",
    },
    {
      name: "Emily",
      voice: "Female English (US)",
    },
  ];

  const [playingUrl, setPlayingUrl] = useState(null);
  const audioRef = useRef(new Audio());

  const playAudio = (url) => {
    if (playingUrl === url) {
      // Toggle play/pause on the current audio
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        setPlayingUrl(null); // Update to ensure the button reflects the paused state
      }
    } else {
      // Stop the currently playing audio (if any)
      if (playingUrl) {
        audioRef.current.pause();
      }
      // Play the new audio
      audioRef.current.src = url;
      audioRef.current.play();
      setPlayingUrl(url);
      audioRef.current.onended = () => {
        setPlayingUrl(null); // Reset when audio ends
      };
    }
  };

  // Ensure to pause the audio when the component unmounts
  useEffect(() => {
    return () => {
      audioRef.current.pause();
    };
  }, []);

  const toggleAnalysisOpen = (index) => {
    setAnalysisOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [triggerSubmit, setTriggerSubmit] = useState(false);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit({ preventDefault: () => {} });
      setTriggerSubmit(false);
    }
  }, [input, triggerSubmit]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  return (
    <main className={`gradient-bg flex flex-col relative w-screen overflow-hidden box-border ${support ? "h-[100%]" : "h-screen"}`}>
      <header className="bg-white p-3 px-6 flex justify-between items-center shrink-0">
        <h2 className="font-semibold text-gray-950">{bot?.name}</h2>
        <div className="text-gray-950 flex items-center gap-3 sm:gap-8">
          <a className="text-sm font-medium flex items-center gap-2 sm:hidden" href={`${process.env.REACT_APP_CALLBACK_URL}/${botId}`} target="_blank">
            <LuMaximize2 className="w-[20px] text-gray/500" />
          </a>
          {/* <button className="text-sm font-medium flex items-center gap-2">
            <LucideFileDown className="w-[20px] text-gray/500" />
            <span className="hidden sm:block">Export</span>
          </button>
          <button className="text-sm font-medium flex items-center gap-2 ">
            <LucideShare2 className="w-[20px] text-gray/500" />
            <span className="hidden sm:block">Share</span>
          </button> */}
        </div>
      </header>

      <div className="text-sm text-gray/950 font-medium text-center m-5 shrink-0">
        <span className="bg-gray-100 p-2 px-3 rounded-sm">Today</span>
      </div>

      <section className={`w-5/6 sm:w-2/3 2xl:w-3/4 mx-auto grow flex flex-col pb-5 ${messages.length !== 0 && 'overflow-hidden'}`}>
        {messages.length === 0 && !isError ? <div className="flex flex-col md:justify-center items-center gap-4 md:gap-10 2xl:gap-16 relative grow">
          <img src={alex} alt="" className="size-16 lg:size-auto" />
          <h1 className="text-center text-gray/500 text-xl lg:text-4xl font-semibold">Hi, I am Alex,<br/>How can I help you today?</h1>
          <div className="flex gap-4 flex-wrap">
            {bot.pre_defined_questions.map((item, i) => (
              <div key={i} className="bg-white p-3 rounded-lg md:w-56 cursor-pointer text-gray/950 text-sm flex flex-col gap-4" onClick={() => {
                setInput(item.question);
                setTriggerSubmit(true);
                }}
              >
                <div className="hidden md:block">
                  <IconPickerItem value={item.icon} size={24} color="#000" />
                </div>
                {item.question}
              </div>
            ))}
          </div>
        </div> : 
          <ScrollArea
            ref={scrollRef}
            className="grow overflow-auto overflow-x-hidden relative overscroll-none pb-5"
          >
            <div className="space-y-5">
              {messages.map((message, i) => {
                const isUser = message.role === "user";
                const audioUrl = message?.annotations?.find(
                  (annotation) => annotation.type === "audio_file"
                )?.data?.title;
                const isPlaying =
                  playingUrl === audioUrl && !audioRef.current.paused;
                const imageUrl = message?.annotations?.find(
                  (annotation) => annotation.type === "image_file"
                )?.data?.image_path;
                const lastAssistantMessage = messages
                  .filter((message) => message.role === "assistant")
                  .pop();
                const sources = message?.annotations?.find(
                  (annotation) => annotation.type === "sources"
                )?.data?.nodes;
                return (
                  <div className="space-y-5" key={i}>
                    {isUser ? (
                      <div className="flex items-start gap-3">
                        <div className="shrink-0">
                          <img
                            className="size-10 object-cover rounded-full"
                            src={
                              user?.picture
                                ? user?.picture
                                : `https://ui-avatars.com/api/?name=${user?.email ? user?.email : 'G'}&bold=true&color=fff&background=${user ? 'cdbbfa' : 'e01e1e'}`
                            }
                            alt=""
                          />
                        </div>
                        <div className="text-[#16171D] font-medium">
                          <h2 className="text-[#16171D] font-semibold">
                            You{" "}
                            <span className="text-gray/500 font-normal text-sm">
                              · {formatDate(message?.createdAt)}
                            </span>
                          </h2>
                          <Markdown content={message.content} />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-start gap-3">
                          {/* <div className="size-10 rounded-full bg-[#FFB3C7] flex items-center justify-center text-[#17120F] shrink-0">
                            <SiKlarna />
                          </div> */}
                          <img className="size-10 rounded-full shrink-0" src={signedImg ? signedImg : 'https://storage.googleapis.com/botstreams-bucket/public/avatar.jpg'} alt="" />
                          <div
                            className={`text-[#16171D] font-medium p-3 px-5 rounded-xl w-full bg-white border ${
                              isPlaying
                                ? "border-[#FFB3C7] shadow-lg shadow-[#FFB3C7]"
                                : "border-white"
                            }`}
                          >
                            <div className="flex justify-between items-start gap-3">
                              <div className="xl:w-5/6">
                                <h2 className="text-[#16171D] font-semibold">
                                  {message?.sender ? message?.sender : bot?.name}{" "} 
                                  <span className="text-gray/500 font-normal text-sm">
                                    · {formatDate(message?.createdAt)}
                                  </span>
                                </h2>
                                <Markdown content={message.content} />
                              </div>
                              {bot.should_generate_audio ? (
                                audioUrl ? (
                                  <button
                                    className="size-10 rounded-full bg-[#FFB3C7] flex items-center justify-center text-[#17120F] shrink-0"
                                    onClick={() => playAudio(audioUrl)}
                                  >
                                    {isPlaying ? (
                                      <BsStopFill className="text-2xl" />
                                    ) : (
                                      <BsPlayFill className="text-2xl" />
                                    )}
                                  </button>
                                ) : (
                                  <Loading className="size-10 mt-3" />
                                )
                              ) : null}
                            </div>
                            <div className="flex items-center gap-2 mt-3 text-gray/500">
                              <button className="">
                                <MdContentCopy />
                              </button>
                              <button>
                                <MdReplay onClick={() => reload()} />
                              </button>
                              <button
                                className="font-normal text-sm flex items-center gap-1"
                                onClick={() => toggleVoiceDropdown(i)}
                              >
                                AI Voice:
                                <span className="text-[#16171D]">Alex</span>
                                {showVoices[i] ? (
                                  <IoIosArrowUp className="text-[#16171D]" />
                                ) : (
                                  <IoIosArrowDown className="text-[#16171D]" />
                                )}
                              </button>
                            </div>
                            {showVoices[i] && (
                              <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-8">
                                {voices.map((voice, i) => (
                                  <button
                                    key={i}
                                    className={`flex items-center gap-3 text-xs text-left border rounded-lg p-1.5 ${
                                      activeVoice === voice.name
                                        ? "border-[#0B051D]"
                                        : "border-gray/200"
                                    }`}
                                    onClick={() => setActiveVoice(voice.name)}
                                  >
                                    <div className="size-6 xl:size-8 bg-blue-200 rounded-full shrink-0"></div>
                                    <div>
                                      <h2>{voice.name}</h2>
                                      <p className="text-[#888FA8] hidden md:block">
                                        {voice.voice}
                                      </p>
                                    </div>
                                  </button>
                                ))}
                              </div>
                            )}
                            {imageUrl && (
                              <div className="mt-3 size-1/3">
                                <img
                                  className="w-full h-full object-contain"
                                  src={imageUrl}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {/* This is sources part */}
                        {sources && <div className="mt-2 ml-14 p-2 border border-gray-500 bg-gray-200 rounded-lg">
                          <h2 className="text-gray-950 text-sm flex justify-between items-center">
                            Analysis
                            <button
                              onClick={() => toggleAnalysisOpen(i)}
                            >
                              <MdArrowDropDown className="text-xl" />
                            </button>
                          </h2>
                          {analysisOpen[i] && (
                            <div>
                              {sources?.map((source, i) => (
                                <p key={i} className="text-xs">{source.text}</p>
                              ))}
                            </div>
                          )}
                        </div>}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* This is error part */}
            {isError && (
              <div className="flex items-start gap-3 mt-5">
                <div className="size-10 rounded-full bg-[#FFB3C7] flex items-center justify-center text-[#17120F] shrink-0">
                  <SiKlarna />
                </div>
                <div className="text-[#16171D] font-medium p-3 px-5 rounded-xl w-full bg-red-200 border border-red-500">
                  <div className="xl:w-5/6">
                    <h2 className="text-[#16171D] font-semibold">
                      Alex{" "}
                      <span className="text-gray/500 font-normal">
                        · 12:16pm
                      </span>
                    </h2>
                    {errorMessage}
                  </div>
                  <div className="flex items-center gap-2 mt-3 text-gray/500">
                    <button className="">
                      <MdContentCopy />
                    </button>
                    <button>
                      <MdReplay
                        onClick={() => {
                          reload();
                          setIsError(false);
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* This is thinking part */}
            {isLoading && (
              <h1 className="flex items-center gap-1 text-sm text-gray/500 mt-2 ml-14">
                {bot.name} is thinking <div className="loader"></div>
              </h1>
            )}
          </ScrollArea>
        }
        <form
          onSubmit={onSubmit}
          className="bg-[#16171D] rounded-[26px] text-white p-2 flex items-end gap-4 shrink-0"
        >
          <textarea
            ref={textareaRef}
            autoFocus
            placeholder="Enter your Prompt"
            rows={1}
            className="resize-none w-full bg-transparent outline-none mb-2.5 ml-4 max-h-60"
            value={input}
            readOnly={isLoading}
            onClick={scrollToBottom}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onSubmit(e);
              }
            }}
          ></textarea>
          {/* <button type="button" className="mb-2 text-[#B2B6C7]">
            <LucideMic />
          </button> */}
          {!isLoading ? <button className="bg-[#FFB3C7] rounded-full p-2">
              <LucideSendHorizonal className="text-[#16171D]" />
            </button> :
            <button className="bg-[#FFB3C7] rounded-full p-2" onClick={() => stop()}>
              <LucideCircleStop className="text-[#16171D]" />
            </button>}
        </form>
        <a href='https://botstreams.ai/' target="_blank" className="text-gray/500 flex items-center justify-center gap-2 text-sm mt-2">
          Powered by <img src={botstream} alt="botstreams" />
        </a>
      </section>
    </main>
  );
};

export default AudioChat;
