import { useChat } from "ai/react";
import React from "react";
import { GoPlus } from "react-icons/go";
import logo from "../../assets/logo.png"
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ user, bot, conversations, messages, setMessages, conversationId, setConversationId, setIsManual, signedImg }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="h-full md:relative group bg-gray/950 text-gray/50 hidden md:w-[250px] md:block shrink-0">
      <div className="h-full">
        <div className="p-5 flex justify-center items-center gap-2">
          <img className="size-6 rounded-full shrink-0" src={signedImg ? signedImg : logo} alt="logo" />
          <h2 className="text-center text-lg font-medium">{bot?.name}</h2>
        </div>
        <div className="p-5 pt-0">
          <button className="border-2 border-gray/700 w-full p-2.5 rounded-lg text-start flex items-center gap-2 text-sm font-medium hover:border-gray/50" onClick={() => {
            setMessages([]);
            setConversationId();
            setIsManual();
          }}>
            <GoPlus className="text-xl" />
            New Chat
          </button>
        </div>
        <div className="mt-5 space-y-3 overflow-y-scroll h-[75.5%] hide-scrollbar">
            {conversations?.today?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Today</h3>
                {conversations?.today?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "bg-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.yesterday?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Yesterday</h3>
                {conversations?.yesterday?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "bg-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.last_week?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Last Week</h3>
                {conversations?.last_week?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "bg-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.last_month?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Last Month</h3>
                {conversations?.last_month?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "bg-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.older?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Older</h3>
                {conversations?.older?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "bg-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
        </div>
      </div>
      <div className="fixed bottom-0 left-0 md:w-[250px]">
        <div className="border-b border-gray/600 w-full"></div>
        {user ? 
        <div className="p-5 flex items-center gap-2 truncate">
            <img className="size-10 object-cover rounded-full" src={user?.picture ? user?.picture : `https://ui-avatars.com/api/?name=${user?.name}&bold=true&color=fff&background=cdbbfa`} alt="profile" />
            <div>
                <h2 className="text-sm font-medium">{user?.name}</h2>
                <p className="text-xs text-gray/500">{user?.email}</p>
            </div>
        </div> :
        <div className="p-5 text-center space-y-3">
          <h2 className="font-light text-sm">Save your chat history?</h2>
          <button className="border-2 border-gray/700 w-full p-2.5 rounded-lg text-sm font-medium hover:border-gray/50" onClick={() => {
            sessionStorage.setItem('redirectAfterLogin', location.pathname);
            navigate('/login');
          }}>
            Login
          </button>
        </div>
        }
      </div>
    </div>
  );
};

export default Sidebar;
