import React from 'react';

const Textarea = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  return (
    <textarea
      className={`flex min-h-[50px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-[#16A34A] placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#16A34A] disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      {...otherProps}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };