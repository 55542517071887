import React, { useEffect } from "react";
import { useLogto } from "@logto/react";
import { Loader2 } from "lucide-react";

const Login = () => {
  const { signIn } = useLogto();
  
  useEffect(() => {
    signIn(`${process.env.REACT_APP_CALLBACK_URL}/callback`);
  }, []);
  
  return (
    <div className="h-screen flex items-center justify-center text-center">
      <div className="w-full h-screen max-h-full flex items-center justify-center text-sm text-muted-foreground">
        <Loader2 className="size-8 animate-spin" />
      </div>
    </div>
  )
};

export default Login;
