import { useLogto } from '@logto/react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }) => {
  const { isAuthenticated, isLoading, signIn } = useLogto();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      sessionStorage.setItem('redirectAfterLogin', location.pathname);
      signIn(`${process.env.REACT_APP_CALLBACK_URL}/callback`);
    }
  }, [isAuthenticated, isLoading, signIn]);

  return isAuthenticated ? children : null;
};

export default RequireAuth;