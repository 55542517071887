import { memo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { CodeBlock } from "./codeblock";

const MemoizedReactMarkdown = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className,
);

export default function Markdown({ content }) {
  return (
    <MemoizedReactMarkdown
      className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words custom-markdown"
      remarkPlugins={[remarkGfm, remarkMath]}
      components={{
        p({ children }) {
          return <p className="mb-2 last:mb-0">{children}</p>;
        },
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");

          if (!className) {
            return (
              <code className={`inline-code ${className}`} {...props}>
                {children}
              </code>
            );
          }

          return (
            <CodeBlock
              key={Math.random()}
              language={(match && match[1]) || ""}
              value={String(children).replace(/\n$/, "")}
              {...props}
            />
          );
        },
        img: ({ node, alt, src, title }) => {
          return (
            <div className="my-3 size-1/3">
              <img src={src} alt={alt} className="w-full h-full object-contain" />
            </div>
          );
        },
        link: ({ node, href, title, children }) => {
          return (
            <a href={href} title={title} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              test tests
            </a>
          );
        },
      }}
    >
      {content}
    </MemoizedReactMarkdown>
  );
}