import React from 'react'
import { Separator } from '../ui/separator'

const Header = ({ bot }) => {
  return (
    <div className="relative">
    <div className="text-center py-4">
      <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
        {bot?.name}
      </h4>
      <h5 className="text-sm text-muted-foreground">{bot?.session?.messages?.length} messages</h5>
    </div>
    <Separator />
  </div>
  )
}

export default Header