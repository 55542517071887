import React from "react";
import { GoPlus } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({
  user,
  bot,
  conversations,
  messages,
  setMessages,
  conversationId,
  setConversationId,
  setIsManual,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="h-full relative group border-r border-gray-300 hidden md:block md:w-[300px] p-5 shrink-0">
      <div className="h-full">
        <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight text-[#09090B]">
          Avatar Chat - UAT
        </h1>
        <h3 className="text-sm text-muted-foreground mt-1">
          Chat bots that know your data
        </h3>
        <div className="p-5">
          <button
            className="border-2 border-gray/700 w-full p-2.5 rounded-lg text-start flex items-center gap-2 text-sm font-medium hover:opacity-60"
            onClick={() => {
              setMessages([]);
              setConversationId();
              setIsManual();
            }}
          >
            <GoPlus className="text-xl" />
            New Chat
          </button>
        </div>
        <div className="mt-5 space-y-3 overflow-y-scroll h-[75.5%] hide-scrollbar">
            {conversations?.today?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Today</h3>
                {conversations?.today?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "border border-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.yesterday?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Yesterday</h3>
                {conversations?.yesterday?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "border border-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.last_week?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Last Week</h3>
                {conversations?.last_week?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "border border-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.last_month?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Last Month</h3>
                {conversations?.last_month?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "border border-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
            {conversations?.older?.length > 0 && <div>
                <h3 className="text-xs text-gray/500 font-medium px-5 mb-1">Older</h3>
                {conversations?.older?.map((item) => (
                  <button key={item._id} className={`text-sm truncate font-medium p-2 px-5 w-full text-start ${item._id === conversationId && "border border-[#353845]"}`} onClick={() => {
                    setMessages(item.session);
                    setConversationId(item._id);
                    setIsManual(item?.manual);
                  }}>
                    {item?.summary ? item?.summary : item.session?.[0]?.content}
                  </button>
                ))}
            </div>}
        </div>
        <div className="fixed bottom-2 left-0 md:w-[299px] bg-white">
          <div className="border-b border-gray/600 w-full"></div>
          {user ? 
          <div className="p-5 flex items-center gap-2 truncate">
              <img className="size-10 object-cover rounded-full" src={user?.picture ? user?.picture : `https://ui-avatars.com/api/?name=${user?.name}&bold=true&color=fff&background=cdbbfa`} alt="profile" />
              <div>
                  <h2 className="text-sm font-medium">{user?.name}</h2>
                  <p className="text-xs text-gray/500">{user?.email}</p>
              </div>
          </div> :
          <div className="p-5 text-center space-y-3">
            <h2 className="font-light text-sm">Save your chat history?</h2>
            <button className="border-2 border-gray/700 w-full p-2.5 rounded-lg text-sm font-medium hover:border-gray/50" onClick={() => {
              sessionStorage.setItem('redirectAfterLogin', location.pathname);
              navigate('/login');
            }}>
              Login
            </button>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
