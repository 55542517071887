import React, { Suspense, useEffect, useRef, useState } from "react";
import { LucideCircleStop, LucideSendHorizonal, LucideSquare } from "lucide-react";
import botstream from "../../assets/botstreams.png";
import botstreamLogo from "../../assets/Avatar Bot.png";
import { useChat } from "ai/react";
import { useParams } from "react-router-dom";
import { FaCode, FaRegStar } from "react-icons/fa";
import { LuWallet } from "react-icons/lu";
import { VscBeaker } from "react-icons/vsc";
import axios from "axios";
import { ScrollArea } from "../../components/ui/scroll-area";
import Markdown from "../../components/ui/markdown";
import {
  MdArrowDropDown,
  MdContentCopy,
  MdPlayArrow,
  MdPlayCircle,
  MdReplay,
} from "react-icons/md";
import { BsPlayFill, BsStopFill } from "react-icons/bs";
import { Loading } from "../../components/ui/loader";
import { IconPickerItem } from "react-icons-picker";

const Chat = ({
  bot,
  user,
  conversationId,
  messages,
  setMessages,
  isError,
  errorMessage,
  setIsError,
  setErrorMessage,
  input,
  setInput,
  handleSubmit,
  handleInputChange,
  isLoading,
  reload,
  stop,
  isManual,
  manualChatSave,
  textareaRef,
  support,
  signedImg
}) => {
  const { botId } = useParams();
  const scrollRef = useRef(null);
  const [analysisOpen, setAnalysisOpen] = useState(true);

  useEffect(() => {
    scrollToBottom();
    setIsError(false);
  }, [messages]);

  const onSubmit = (e) => {
    if(isManual){
      manualChatSave(e);
    }
    else handleSubmit(e);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const textareas = document.querySelectorAll("textarea");
    textareas.forEach((element) => {
      element.style.height = `${element.scrollHeight}px`;
      element.addEventListener("input", (event) => {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
      });
    });

    // Cleanup event listeners on component unmount
    return () => {
      textareas.forEach((element) => {
        element.removeEventListener("input", (event) => {
          event.target.style.height = "auto";
          event.target.style.height = `${event.target.scrollHeight}px`;
        });
      });
    };
  }, []);

  const [playingUrl, setPlayingUrl] = useState(null);
  const audioRef = useRef(new Audio());

  const playAudio = (url) => {
    if (playingUrl === url) {
      // Toggle play/pause on the current audio
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        setPlayingUrl(null); // Update to ensure the button reflects the paused state
      }
    } else {
      // Stop the currently playing audio (if any)
      if (playingUrl) {
        audioRef.current.pause();
      }
      // Play the new audio
      audioRef.current.src = url;
      audioRef.current.play();
      setPlayingUrl(url);
      audioRef.current.onended = () => {
        setPlayingUrl(null); // Reset when audio ends
      };
    }
  };

  // Ensure to pause the audio when the component unmounts
  useEffect(() => {
    return () => {
      audioRef.current.pause();
    };
  }, []);

  const toggleAnalysisOpen = (index) => {
    setAnalysisOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [triggerSubmit, setTriggerSubmit] = useState(false);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit({ preventDefault: () => {} });
      setTriggerSubmit(false);
    }
  }, [triggerSubmit]);

  return (
    <main className={`w-full flex flex-col ${support && "h-[90%]"}`}>
      <section className="w-5/6 sm:w-2/3 2xl:w-3/4 mx-auto mt-10 grow flex flex-col overflow-hidden pb-5">
        {messages.length === 0 && !isError ? (
          <div className="flex flex-col md:justify-center items-start gap-5 lg:gap-72 relative h-full">
            <div className="space-y-3">
              <img className="size-9 rounded-full" src={signedImg ? signedImg : botstreamLogo} alt="botstreams" />
              <h1 className="text-gray/500 font-medium text-xl lg:text-4xl">
                How can I help you today?
              </h1>
            </div>
            <div className="flex gap-4 flex-wrap">
              {bot.pre_defined_questions.map((item, i) => (
                <div
                  key={i}
                  className="bg-gray/100 p-3 rounded-lg md:w-56 cursor-pointer text-gray/950 text-sm flex flex-col gap-4"
                  onClick={() => {
                    setInput(item.question);
                    setTriggerSubmit(true);
                  }}
                >
                  <div className="hidden md:block">
                    <IconPickerItem value={item.icon} size={24} color="#000" />
                  </div>
                  {item.question}
                </div>
              ))}
            </div>
          </div>
        ) : (
            <ScrollArea
              ref={scrollRef}
              className="grow overflow-auto overflow-x-hidden relative overscroll-none pb-5"
            >
              <div className="space-y-8">
                {messages.map((message, i) => {
                  const isUser = message.role === "user";
                  const audioUrl = message?.annotations?.find(
                    (annotation) => annotation.type === "audio_file"
                  )?.data?.title;
                  const isPlaying =
                    playingUrl === audioUrl && !audioRef.current.paused;
                  const imageUrl = message?.annotations?.find(
                    (annotation) => annotation.type === "image_file"
                  )?.data?.image_path;
                  const lastAssistantMessage = messages
                    .filter((message) => message.role === "assistant")
                    .pop();
                  const sources = message?.annotations?.find(
                    (annotation) => annotation.type === "sources"
                  )?.data?.nodes;
                  return (
                    <div key={i}>
                      {isUser ? (
                        <div className="flex items-center gap-4">
                          <img
                            className="size-5 object-cover rounded-full"
                            src={
                              user?.picture
                                ? user?.picture
                                : `https://ui-avatars.com/api/?name=${'G'}&bold=true&color=fff&background=${user ? 'cdbbfa' : 'e01e1e'}`
                            }
                            alt=""
                          />
                          <h1 className="text-2xl font-medium">
                            <Markdown content={message.content} />
                          </h1>
                        </div>
                      ) : (
                        <div className="ml-10">
                          <h1 className="text-sm font-normal">
                            {bot?.name === "OpenAI chatbot with database reader" ? message.content : <Markdown content={message.content} /> }
                          </h1>
                          <div className="mt-2 flex items-center gap-2 text-gray/500">
                            {bot.should_generate_audio ? (
                              audioUrl ? (
                                <button onClick={() => playAudio(audioUrl)}>
                                  {isPlaying ? (
                                    <BsStopFill className="text-2xl" />
                                  ) : (
                                    <BsPlayFill className="text-2xl" />
                                  )}
                                </button>
                              ) : (
                                <Loading className="size-10 mt-3" />
                              )
                            ) : null}
                            <button>
                              <MdContentCopy />
                            </button>
                            <button>
                              <MdReplay
                                className="text-lg"
                                onClick={() => reload()}
                              />
                            </button>
                            {message?.sender && <h2 className="text-xs">- {message?.sender}</h2>}
                          </div>
                          {/* This is image part */}
                          {imageUrl && (
                            <div className="mt-3 size-1/3">
                              <img
                                className="w-full h-full object-contain"
                                src={imageUrl}
                                alt=""
                              />
                            </div>
                          )}
                          {/* This is sources part */}
                          {sources && (
                            <div className="mt-4 p-2 border bg-gray-500 text-white rounded-lg">
                              <button
                                className="text-xs font-thin flex items-center"
                                onClick={() => toggleAnalysisOpen(i)}
                              >
                                Analysis
                                <MdArrowDropDown className="text-xl" />
                              </button>
                              {analysisOpen[i] && (
                                <div>
                                  {sources?.map((source) => (
                                    <p className="text-xs">{source.text}</p>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                          {i !== messages.length - 1 && (
                            <div className="border-b w-full mt-10"></div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* This is error part */}
              {isError && (
                <div className="flex items-end gap-3 ml-10">
                  <div className="space-y-1">
                    <div className="text-[#16171D] text-sm bg-red-200 border border-red-500 p-2 rounded-md">
                      {errorMessage}
                    </div>
                    <div className="flex items-center gap-2 mt-4 text-gray/500">
                      <button className="">
                        <MdContentCopy />
                      </button>
                      <button>
                        <MdReplay
                          onClick={() => {
                            reload();
                            setIsError(false);
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* This is thinking part */}
              {isLoading && (
                <h1 className="flex items-center gap-1 text-sm text-gray/500 mt-2 ml-10">
                  {bot.name} is thinking <div className="loader"></div>
                </h1>
              )}
            </ScrollArea>
        )}
        <div className="shrink-0">
          <form
            onSubmit={onSubmit}
            className="bg-white border-2 rounded-md p-3 flex items-end gap-4"
          >
            <textarea
              autoFocus
              ref={textareaRef}
              placeholder="Ask me anything"
              rows={1}
              className="resize-none w-full bg-transparent outline-none mb-2.5 ml-4 max-h-60"
              value={input}
              readOnly={isLoading}
              onClick={scrollToBottom}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  onSubmit(e);
                }
              }}
            ></textarea>
            {!isLoading ? <button className="bg-blue/500 rounded-full p-2">
              <LucideSendHorizonal className="text-white" />
            </button> :
            <button className="bg-blue/500 rounded-full p-2" onClick={() => stop()}>
              <LucideCircleStop className="text-white" />
            </button>}
          </form>
          <a href='https://botstreams.ai/' target="_blank" className="text-gray/500 flex items-center justify-center gap-2 text-sm mt-2">
            Powered by <img src={botstream} alt="botstreams" />
          </a>
        </div>
      </section>
    </main>
  );
};

export default Chat;
