import { useLogto } from '@logto/react';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { signOut, isAuthenticated, clearAllTokens } = useLogto();
  const navigate = useNavigate();

  return (
    <div className='p-5'>
        {isAuthenticated ? <button className='bg-green-500 text-white p-2 rounded-md' onClick={() => {
          signOut(process.env.REACT_APP_CALLBACK_URL);
          localStorage.removeItem('org');
          clearAllTokens();
        }}>
            Logout
        </button> : 
        <button className='bg-green-500 text-white p-2 rounded-md' onClick={() => {
          navigate('/login')
        }}>
          Login
        </button>
        }
    </div>
  )
}

export default Home