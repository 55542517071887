import { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Callback from './pages/callback';
import Login from './pages/login';
import { useLogto } from '@logto/react';
import Home from './pages/home';
import RequireAuth from './RequireAuth';
import BotStatus from './BotStatus';
import Join from './pages/join';
import SupportChat from './pages/support-chat';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false); 
  const { isAuthenticated, fetchUserInfo } = useLogto();
  
  useEffect(() => {
    (async () => {
      if (isAuthenticated) { 
        await getTokens();
      }
    })();
  }, [isAuthenticated]);

  const getTokens = async () => {
    setLoading(true);

    const user = await fetchUserInfo();
    setUser(user);
    // localStorage.setItem('org', user?.organizations[0]);

    // const accessToken = await getAccessToken(process.env.REACT_APP_ADMIN_API_URL, user?.organizations[0]);
    // setToken(accessToken)
    // localStorage.setItem('token', accessToken);

    setLoading(false);
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route path="/callback" element={<Callback setLoading={setLoading} setToken={setToken} setUser={setUser} />} />
        <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
        <Route path="/join/:inviteId" element={<RequireAuth><Join user={user} getTokens={getTokens} /></RequireAuth>} />
        <Route path="/:botId" element={<BotStatus loading={loading} setLoading={setLoading} user={user} token={token} support={false} />} />
        <Route path="support/:botId" element={<SupportChat loading={loading} setLoading={setLoading} user={user} token={token} support={true} />} />
      </Routes>
    </>
  );
}

export default App;
